/*----------------------------------------*/
/*  04. About CSS
/*----------------------------------------*/

/*About Content*/
.about-content {
    & .about-heading {
        & .sub {
            line-height: 1;
        }
        & .title {
            line-height: 1.25;
            margin-bottom: 25px;
        }
    }
    .desc {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        & p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    & .btn {}
}

/*About Image*/
.about-image {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba($black, 0.1);
    margin-right: 40px;
    & img {
        width: 100%;
    }
    // Responsive
    @media #{$tablet-device, $large-mobile} {
        margin-right: 0;
    }
}