.bard-content div > p:first-child {
    margin-top: 0;
}

article.post {
    margin-bottom: 4em !important;
}

/** image_set **/
figure.image_set {
    display: flex;
    flex-flow: column;
    position: relative;
    @media (max-width: 960px) {
        margin-left: 0;
        margin-bottom: 1.5em;
    }
    a img {
        transition: all .2s ease-in-out;
    }
    a img:hover {
        transform:  scale(1.05);
    }
    &.width-quarter {
        width: 25%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-half {
        width: 50%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-three-quarter {
        width: 75%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-full {
        width: 100%;
    }

    &.left {
        float: left;
        margin-top: .5em;
        margin-right: 1em;
        margin-bottom: 5px;

        @media (max-width: 960px) {
            margin-right: 0;
            margin-bottom: 1.5em;
        }
    
    }

    &.right {
        float: right;
        margin-top: .5em;
        margin-left: 1em;
        margin-bottom: 5px;

        @media (max-width: 960px) {
            margin-left: 0;
            margin-bottom: 1.5em;
        }
    }

    &.center {
        margin: 0 auto;
        margin-top: .5em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            width: 100%;
            margin-bottom: 1.5em;
        }
    }

}
figcaption.title {
    background-color: rgba(63, 63, 63,.6);
    color: #fff;
    font: italic smaller sans-serif;
    padding: 5px 5px;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
}


.frame figcaption.title {
    width: calc(100% - 10px);
    bottom: 5px;
}

figure.frame {
    padding: 5px;
    border: 1px solid #e0e0e0;
    display: block;
    // margin: 0 auto;
    box-shadow: 0px 0px 6px rgba(110, 110, 110, 0.2);
    }

.fancybox-caption {
    font-size: 1.4rem;
}

/** header_set **/

h2, h3, h4, h5, h6 {
    margin: 2rem 0 .8rem 0;
}

.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}

/** video_set **/
.bard-video {
    &.width-quarter {
        width: 25%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-half {
        width: 50%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-three-quarter {
        width: 75%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    &.width-full {
        width: 100%;
    }

    &.left {
        float: left;
        margin-top: .5em;
        margin-right: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-right: 0;
            margin-bottom: 1.5em;
        }
    }

    &.right {
        float: right;
        margin-top: .5em;
        margin-left: 2em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            margin-left: 0;
            margin-bottom: 1.5em;
        }
    }

    &.center {
        margin: 0 auto;
        margin-top: .5em;
        margin-bottom: 1em;

        @media (max-width: 960px) {
            width: 100%;
            margin-bottom: 1.5em;
        }
    }
}

/** horizontal_rule_set **/
.horizontal-rule {
    margin: 0;
    padding: 0;
    &.width-new-line hr {
        width: 100%;
        display: inline-block;
        clear: both;
    }
    &.none hr{
        margin: 0;
    }
    &.m-15 hr{
        margin: 15px 0;
    }

    &.m-30 hr{
        margin: 30px 0;
    }

    &.m-60 hr{
        margin: 60px 0;
    }
}

/** button_set **/
.bard-button {
    margin: 1.5em 0 2em;
    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }
}
.bard-button a {
    border-radius: 15px;
    &.btn-xs {
        padding: 5px;
        font-size: 1em;
    }
    &.btn-sm {
        padding: .8rem;
        font-size: 1rem;
    }
    &.btn-md {
        padding: 1.2rem;
        font-size: 1.2rem;
    }
    &.btn-lg {
        padding: 1.5rem;
        font-size: 1.5rem;
    }
    &.alert-primary {
        background-color: $primary-color;
        color: darken($primary-color,20%);
        border: 2px solid darken($primary-color,40%);
        &:hover {
            background-color: darken($primary-color,20%) !important;
            color: $white !important;
        }
    }
    &.alert-secondary {
        background-color: $secondary-color;
        color: lighten($secondary-color,50%);
        border: 2px solid darken($secondary-color,40%);
        &:hover {
            background-color: lighten($secondary-color,40%) !important;
            color: $black !important;
        }
    }
    &.alert-tertiary {
        background-color: $tertiary-color;
        color: lighten($tertiary-color,20%);
        border: 2px solid darken($tertiary-color,40%);
        &:hover {
            background-color: darken($tertiary-color,20%) !important;
            color: $white !important;
        }
    }
    &.alert-dark {
        background-color: $black;
        color: lighten($black,45%);
        &:hover {
            background-color: darken($black,20%) !important;
            color: $white !important;
        }
    }
    &.alert-light {
        background-color: darken($white,7%);
        color: darken($white,50%);
        &:hover {
            background-color: darken($white,20%) !important;
            color: $white !important;
        }
    }
    &.btn-outline-default {

    }
    &.btn-outline-dark {
        border: 4px solid lighten($black,50%);
    }
    &.btn-outline-primary {
        border: 4px solid darken($primary-color,20%);
        &:hover {
            border: 4px solid $primary-color !important;
        }
    }    
    &.btn-outline-secondary {
        border: 4px solid darken($secondary-color,20%);
        &:hover {
            border: 4px solid $secondary-color !important;
        }
    }   
    &.btn-outline-tertiary {
        border: 4px solid darken($tertiary-color,20%);
        &:hover {
            border: 4px solid $tertiary-color !important;
        }
    }
    &.btn-outline-link {
        border: none;
    }
}



/*** BUTTONS ***/
.btn-primary {
    background-color: $primary;
    color: #fff !important;
    &:hover {
        background-color: #fff;
    color: $primary !important;
    
    }
}
.btn-secondary {
    background-color: $secondary;
    color: #fff !important;
    &:hover {
        background-color: #fff;
    color: $secondary !important;
    }
}
.btn-outline-Dark {
    border: 4px solid rgb(49, 49, 49);
}
.btn-outline-Light {
    border: 4px solid #ccc;
}
.btn-outline-primary {
    border: 4px solid $primary;
}
.btn-outline-secondary {
    border: 4px solid $secondary;
}
.btn-primary:hover {
    border: 4px solid darken($primary,6.5%);
}

/*** BUTTON SIZES ****/

.btn-lg {
    padding: 20px 30px;
    font-size: 27px;
    line-height: 1.3333333;
    border-radius: 6px;
}
.btn-md {
    padding: 10px 30px;
    font-size: 22px;
    line-height: 1.3333333;
    border-radius: 6px;
}
.btn-sm {
    padding: 5px 25px;
    font-size: 19px;
    line-height: 1.3333333;
    border-radius: 6px;
}
.btn-xs {
    padding: 5px 15px;
    font-size: 16px;
    line-height: 1.3333333;
    border-radius: 6px;
}

/** image_gallery_set **/
.bard-gall {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .bard-gallery {
        padding: 5px;
        @media screen and (max-width: 720px) {
            max-width: 50% !important;
        }
    }
    .bard-gallery.frame {
        border: 1px solid rgb(221, 221, 221);
        padding: 8px;
        margin: 4px;
    }
    .bard-gallery.columns-2 {
        max-width: 48%;
    }
    .bard-gallery.columns-4 {
        max-width: 23%;
    }
    .bard-gallery.columns-6 {
        max-width: 13%;
    }


    .bard-gallery img {
        // margin-bottom: 30px;
        transition: all .2s ease-in-out;
    }
    .bard-gallery img:hover {
        transform: scale(1.1);
    }
    .bard-gallery img.no-zoom:hover {
        transform: none;
    }
}

/** blockquote **/
blockquote {
    margin: 15px 0 20px;
    color: grey;
}

/** tabs_set **/
.tabs-set {
    margin: 2em 0;
    background-color: #f1f1f1;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    
    .tab-content {
        padding: 1em;
    }
}
ul.nav-tabs li {
    margin: 1rem;
}

/*** list_set ***/
.list_set {

    ul {
        margin: 0;
        padding: 0;
    }
    li {
        margin-left: 2em;
    }
}

/*** Table List Set ***/

table.table_set {
    font-size: 1rem;
    line-height: 1.4rem;
    a {
        text-decoration: underline;
        font-size: .8rem;
    }
    &.table-striped tbody tr:nth-of-type(2n+1) {
        background-color: rgb(233, 233, 233);
    }
    &.table-hover tbody tr:hover {
        background-color: rgb(212, 212, 212);
    }
    
}