/*** FNIONS ***/

@font-face {
  font-family: 'fnions';
  src:
    url('/site/themes/default/fonts/fnions.ttf?lx94z6') format('truetype'),
    url('/site/themes/default/fonts/fnions.woff?lx94z6') format('woff'),
    url('/site/themes/default/fonts/fnions.svg?lx94z6#fnions') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fnions {
    font-family: 'fnions' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fnions-hours:before {
  content: "\e900";
}
.fnions-analysis:before {
  content: "\e901";
}
.fnions-call:before {
  content: "\e902";
}
.fnions-chat:before {
  content: "\e903";
}
.fnions-connection:before {
  content: "\e904";
}
.fnions-contract:before {
  content: "\e905";
}
.fnions-conversation:before {
  content: "\e906";
}
.fnions-customer-service:before {
  content: "\e907";
}
.fnions-email:before {
  content: "\e908";
}
.fnions-employee:before {
  content: "\e909";
}
.fnions-gps:before {
  content: "\e90a";
}
.fnions-marker:before {
  content: "\e90b";
}
.fnions-money:before {
  content: "\e90c";
}
.fnions-piggy-bank:before {
  content: "\e90d";
}
.fnions-refinancing:before {
  content: "\e90e";
}
.fnions-rocket:before {
  content: "\e90f";
}
.fnions-search:before {
  content: "\e910";
}
.fnions-smiling:before {
  content: "\e911";
}
.fnions-suitcase:before {
  content: "\e912";
}
.fnions-team:before {
  content: "\e913";
}
.fnions-trophy:before {
  content: "\e914";
}
.fnions-world:before {
  content: "\e915";
}
.fnions-close:before {
  content: "\e916";
}