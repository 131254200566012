/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
.header {
    width: 100%;
    z-index: 999;
    background-color: $black;
}
/*Header Sticky*/
.is-sticky {
    & .header-bottom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
        animation-duration: 0.6s;
        animation-fill-mode: both;
        animation-name: is-sticky;
    }
}
@keyframes is-sticky {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/*Header Bottom*/
.header-bottom {
    background-color: $black;
    padding: 0 85px;
    z-index: 9999;
    // Responsive
    @media #{$laptop-device, $desktop-device} {
        padding: 0 15px;
    }
    @media #{$tablet-device, $large-mobile} {
        padding: 25px   15px;
    }
}

/*Header Bottom Left*/
.header-bottom-left {
    order: 1;
    display: flex;
    justify-content: flex-start;
}
/*Header Logo*/
.header-logo {
    & a {
        position: relative;
        display: flex;
        transition: none;
        align-items: center;
        transition: none;
        & span {
            display: inline-flex;
            & img {
                max-width: 250px;
                width: 250px;
                object-fit: contain; 
                height: auto;
            }
            &.logo-light {
                opacity: 1;
            }
            &.logo-dark {
                position: absolute;
                left: 0;
                top: 50%;
                opacity: 0;
                transform: translateY(-50%);
            }
        }
    }
}

/*Header Bottom Navigation*/
.header-bottom-middle {
    order: 2;
}

/*--
    Site Main Navigation
------------------------------------------*/
.site-main-navigation {
    float: left;
}
.template-home .site-nav li a {
    padding-top: 35px;
}
.template-home .sub-menu li a {
    padding: 7px 25px;
}
/*Site Nav*/
.site-nav {
    margin-right: 45px;
    & > ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        & > li {
            margin-right: 45px;
            &:last-child {
                margin-right: 0;
            }
            & > a {
                line-height: 30px;
                padding: 55px 0 35px 0;
                display: block;
                text-transform: uppercase;
                position: relative;
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
            &:hover {
                & > .sub-menu {
                    visibility: visible;
                    opacity: 1;
                    z-index: 999;
                    margin-top: 0;
                }
            }
        }
    }
    // Responsive
    @media #{$desktop-device} {
        margin-right: 20px;
        & > ul {
            & > li {
                margin-right: 35px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}




/*Sub Menu*/
.sub-menu {
    margin-top: 20px;
    margin-left: -25px;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: -99;
    visibility: hidden;
    transition: all 0.15s linear 0.1s;
    opacity: 0;
    min-width: 220px;
    padding: 15px 0;
    background-color: $white;
    box-shadow: 0 2px 12px 2px rgba($black, 0.1);
    list-style: none;
    & li {
        position: relative;
        & a {
            display: block;
            padding: 7px 25px;
            font-size: 15px;
            line-height: 28px;
            position: relative;
            display: flex;
            justify-content: space-between;
        }
        &.menu-item-has-children, &.has-children, &.has-sub-menu {
            & > a {
                &::after {
                    content: "\f105";
                    display: block;
                    font-family: $icon-fontawesome;
                    padding-left: 10px;
                }
            }
        }
        & .sub-menu {
            top: 0;
            left: 100%;
            margin-top: 0;
            margin-left: -20px;
            transition: all 250ms ease-out;
            & li {
                & .sub-menu {
                    right: 100%;
                    left: auto;
                    margin-left: 0;
                    margin-right: -20px;
                }
            }
        }
        &:hover {
            & > .sub-menu {
                visibility: visible;
                opacity: 1;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}

/*Header Bottom Right*/
.header-bottom-right {
    order: 3;
    display: flex;
    justify-content: flex-end;
    transition: all 0.3s ease 0s;
}
/*Header Toggle Buttons*/
.header-toggle-buttons {
    position: relative;
    float: right;
    align-self: center;
    & .header-toggle {
        float: left;
        margin-left: 45px;
        &:first-child {
            margin-left: 0;
        }
        & .toggle {
            width: 30px;
            height: 30px;
            display: block;
            text-align: center;
            position: relative;
            color: $white;
            & i {
                font-size: 24px;
                line-height: 30px;
                display: block;
            }
            &:hover {
                color: $primary;
            }
        }
    }
    // Responsive
    @media #{$extra-small-mobile} {
        & .header-toggle {
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
/*Off Canvas Toggle*/
.toggle-off-canvas {
    & .toggle {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: flex-end;
        & .bar {
            display: block;
            height: 1px;
            flex: 0 0 30px;
            background-color: $white;
            margin: 4px 0;
            transition: all 0.3s ease 0s;
            &.bar-1 {
                flex: 0 0 20px;
            }
        }
        &:hover {
            & .bar {
                background-color: $primary;
                &.bar-1 {
                    flex: 0 0 30px;
                }
            }
        }
    }
}

/*--
    Search Overlay
------------------------------------------*/
/*Search Form Overlay*/
.search-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($black, 0.94);
    text-align: center;
    transition: all 0.3s ease 0.25s;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba($white, 0.8);
    &.search-overlay-open {
        visibility: visible;
        opacity: 1;
        & .search-form {
            transform: scale(1);
            visibility: visible;
            opacity: 1;
        }
    }
    //Search Close Button
    & .search-close {
        font-size: 30px;
        display: block;
        position: absolute;
        top: 20%;
        right: 20%;
        line-height: 40px;
        height: 40px;
        &:hover {
            transform: rotate(90deg);
        }
        @media #{$large-mobile} {
            top: 20px;
            right: 20px;
        }
    }
    //Search Form Fly
    & .search-form {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        transform: scale(0.9);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out  0.25s;
        width: 800px;
        max-width: 90%;
        & p {
            font-weight: 300;
            margin-bottom: 40px;
        }
        & form {
            position: relative;
            & .search-field {
                background-color: transparent;
                box-shadow: 0 3px 0 0 rgba($white, 0.1);
                border: 0;
                text-align: center;
                font-size: 24px;
                line-height: 30px;
                padding: 20px 45px;
                color: rgba($white, 0.8);
                transition: all 0.3s ease-out 0s;
                font-weight: 300;
                width: 100%;
                &:focus {
                    box-shadow: 0 3px 0 0 rgba($white, 0.1) !important;
                }
            }
            & .search-button {
                position: absolute;
                top: 20px;
                right: 10px;
                display: flex;
                align-items: center;
                border: none;
                background-color: transparent;
                padding: 0;
                font-size: 24px;
                height: 30px;
            }
        }
    }
}

/****  INNER PAGE HEADER ****/

.inner-page-header {
    background-color: rgba(0,0,0,.3);
    padding: 2em;
    border-radius: 10px;
}



/*--
    Off Canvas
------------------------------------------*/
.off-canvas {
    position: fixed;
    right: -400px;
    top: 0;
    height: 100vh;
    width: 400px;
    background-color: lighten($black, 10);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 50px 0 rgba($black,.09);
    transition: all 400ms ease-out;
    z-index: 9999;
    &.off-canvas-open {
        opacity: 1;
        visibility: visible;
        right: 0;
    }
    @media #{$extra-small-mobile} {
        width: 280px;
    }
}
/*Off Canvas Wrap*/
.off-canvas-wrap {
    height: 100%;
    padding: 80px 0;
    @media #{$large-mobile} {
        padding: 60px 0 40px;
    }
}
/*Off Canvas Close Button*/
.off-canvas-close {
	position: absolute;
	left: 60px;
	top: 30px;
	z-index: 9;
	color: $white;
	text-align: center;
    box-shadow: 0 0 30px 0 rgba($black, 0.09);
    @media #{$extra-small-mobile} {
        left: 30px;
        top: 20px;
        & i {
            font-size: 16px;
        }
    }
    & i {
        font-size: 24px;
        line-height: 1;
        display: block;
        transition: all .3s ease-in-out;
    }
    &:hover {
        color: $primary;
        & i {
            transform: rotate(90deg);
        }
    }
}
/*Off Canvas Inner*/
.off-canvas-inner {
    padding: 0 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    @media #{$large-mobile} {
        padding: 0 30px;
    }
}
/*Off Canvas Nav*/
.off-canvas-nav {
    margin-bottom: 40px;
    color: $white;
    &:last-child {
        margin-bottom: 0;
    }
    & > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        & > li {
            position: relative;
            & > a {
                display: block;
                transition: all 0.2s ease 0s;
                text-transform: uppercase;
                font-size: 18px;
                padding: 10px 0;
            }
            &:hover {
                & > .menu-expand {
                    & i {
                        border-color: $primary;
                        &::before {
                            border-color: $primary;
                        }
                    }
                }
            }
            & .menu-expand {
                position: absolute;
                right: 0;
                top: -1px;
                width: 30px;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                text-align: center;
                z-index: 5;
                & i {
                    display: block;
                    margin-top: 25px;
                    margin-left: auto;
                    margin-right: auto;
                    border-bottom: 1px solid;
                    position: relative;
                    width: 14px;
                    transition: all 250ms ease-out;
                    &::before {
                        width: 100%;
                        content: "";
                        border-bottom: 1px solid;
                        display: block;
                        position: absolute;
                        top: 0;
                        transform: rotate(90deg);
                        transition: all 250ms ease-out;
                    }
                }
                &:hover {
                    & i {
                        border-color: $primary;
                        &::before {
                            border-color: $primary;
                        }
                    }
                }
            }
            &:hover {
                & > ul, & > .sub-menu {
                    margin-left: 35px !important;
                }
            }
            &.active {
                & > .menu-expand {
                    & i {
                        &::before {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
            & ul, & .sub-menu {
                background-color: transparent;
                list-style: none;
                padding: 0;
                margin-left: 35px;
                position: static;
                opacity: 1;
                visibility: visible;
                box-shadow: none;
                margin-top: 0;
                width: auto;
                transition: none;
                & li {
                    position: relative;
                    &.menu-item-has-children, &.has-children, &.has-sub-menu {
                        & > a {
                            &::after {
                                display: none;
                            }
                        }
                    }
                    & a {
                        display: block;
                        transition: all 0.2s ease 0s;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 30px;
                        padding: 7px 0;
                    }
                    &:hover {
                        & > ul, & > .sub-menu {
                            margin-left: 35px !important;
                        }
                    }
                    &.active {
                        & .menu-expand {
                            & i {
                                &::before {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*Off Canvas Widget*/
.off-canvas-widget-wrap {}

/*Widget*/
.sidebar-widget {
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
}

/*Social Widget*/
.sidebar-widget-social {
    display: flex;
    flex-wrap: wrap;
    & a {
        margin: 0 15px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}