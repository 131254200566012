/*--
    - Button
-----------------------------------------*/
.btn {
    font-size: 14px;
    line-height: 28px;
    padding: 10px 40px;
    text-transform: uppercase;
    
    &.btn-light {
        background-color: #f8f9fa;
        border-color: #f8f9fa;
        &:hover {
            background-color: darken(#f8f9fa, 5);
            border-color: darken(#f8f9fa, 5);
        }
    }
    &.btn-light-outline {
        background-color: transparent;
        border-color: #f8f9fa;
        color: #f8f9fa;
        &:hover {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            color: $heading-color;
        }
    }
    &.btn-dark {
        background-color: $dark;
        border-color: $dark;
        &:hover {
            background-color: darken($dark, 5);
            border-color: darken($dark, 5);
        }
    }
    &.btn-dark-outline {
        background-color: transparent;
        border-color: $dark;
        color: $dark;
        &:hover {
            background-color: $dark;
            border-color: $dark;
            color: $white;
        }
    }
    &.btn-primary {
        background-color: $primary;
        border-color: $primary;
        &:hover {
            background-color: darken($primary, 5);
            border-color: darken($primary, 5);
        }
    }
    &.btn-primary-outline {
        background-color: transparent;
        border-color: $primary;
        color: $primary;
        &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    &.btn-secondary {
        background-color: $secondary;
        border-color: $secondary;
        &:hover {
            background-color: darken($secondary, 5);
            border-color: darken($secondary, 5);
        }
    }
    &.btn-secondary-outline {
        background-color: transparent;
        border-color: $secondary;
        color: $secondary;
        &:hover {
            background-color: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }
    &.btn-success {
        background-color: $success;
        border-color: $success;
        &:hover {
            background-color: darken($success, 5);
            border-color: darken($success, 5);
        }
    }
    &.btn-success-outline {
        background-color: transparent;
        border-color: $success;
        color: $success;
        &:hover {
            background-color: $success;
            border-color: $success;
            color: $white;
        }
    }
    &.btn-danger {
        background-color: $danger;
        border-color: $danger;
        &:hover {
            background-color: darken($danger, 5);
            border-color: darken($danger, 5);
        }
    }
    &.btn-danger-outline {
        background-color: transparent;
        border-color: $danger;
        color: $danger;
        &:hover {
            background-color: $danger;
            border-color: $danger;
            color: $white;
        }
    }
    &.btn-warning {
        background-color: $warning;
        border-color: $warning;
        &:hover {
            background-color: darken($warning, 5);
            border-color: darken($warning, 5);
        }
    }
    &.btn-warning-outline {
        background-color: transparent;
        border-color: $warning;
        color: $warning;
        &:hover {
            background-color: $warning;
            border-color: $warning;
            color: $white;
        }
    }
    &.btn-info {
        background-color: $info;
        border-color: $info;
        &:hover {
            background-color: darken($info, 5);
            border-color: darken($info, 5);
        }
    }
    &.btn-info-outline {
        background-color: transparent;
        border-color: $info;
        color: $info;
        &:hover {
            background-color: $info;
            border-color: $info;
            color: $white;
        }
    }
    // Hover
    &.btn-hover-light {
        &:hover {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            color: $heading-color;
        }
    }
    &.btn-hover-dark {
        &:hover {
            background-color: $dark;
            border-color: $dark;
            color: $white;
        }
    }
    &.btn-hover-primary {
        &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
    &.btn-hover-secondary {
        &:hover {
            background-color: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }
    &.btn-hover-success {
        &:hover {
            background-color: $success;
            border-color: $success;
            color: $white;
        }
    }
    &.btn-hover-danger {
        &:hover {
            background-color: $danger;
            border-color: $danger;
            color: $white;
        }
    }
    &.btn-hover-warning {
        &:hover {
            background-color: $warning;
            border-color: $warning;
            color: $white;
        }
    }
    &.btn-hover-info {
        &:hover {
            background-color: $info;
            border-color: $info;
            color: $white;
        }
    }
}