/*--
    - Form
-----------------------------------------*/
.form {
    margin-bottom: -30px;
}
.input-group {
    margin-bottom: 30px;
}
.input-control:not([type="submit"]) {
    display: block;
    border: 1px solid #eeeeee;
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
    line-height: 28px;
    padding: 10px 20px;
    color: $body-color;
    @include placeholder {
        color: $body-color;
    }
}