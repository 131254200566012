/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/

/*Hero Slider*/
.hero-slider {
    // Arrows
    & .slick-arrow {
        border: none;
        background-color: transparent;
        color: $white;
        padding: 0 15px;
        width: auto;
        height: auto;
        & i {
            font-size: 60px;
        }
        &.slick-prev {
            left: 30px;
        }
        &.slick-next {
            right: 30px;
        }
        &:hover {
            color: $primary;
        }
    }
    // Pagination Dots
    & .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        margin: 0;
        & li {
            & button {
                width: 14px;
                height: 14px;
            }
        }
    }
    // Responsive
    @media #{$tablet-device, $large-mobile} {
        & .slick-arrow {
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }
        }
    }
    @media #{$large-mobile} {
        & .slick-dots {
            bottom: 20px;
        }
    }
    @media #{$extra-small-mobile} {
        & .slick-dots {
            bottom: 10px;
        }
    }
}

/*Hero Item*/
.hero-item {
    height: 850px;
    width: 100%;
    flex: 1 0 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // Responsive
    @media #{$laptop-device, $desktop-device, $tablet-device} {
        height: 670px;
    }
    @media #{$large-mobile} {
        height: 350px;
    }
    @media #{$small-mobile} {
        height: 350px;
    }
}

/*Hero Content*/
.hero-content {
    padding-top: 40px;
    padding-bottom: 40px;
    & .title, & .desc {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
    & .title {
        color: $white;
        font-size: 66px;
        text-transform: uppercase;
        margin-bottom: 15px;
        animation-delay: 0.3s;
    }
    & .desc {
        color: $white;
        font-size: 24px;
        max-width: 930px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 38px;
        animation-delay: 0.7s;
    }
    & .links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        & a {
            animation-duration: 1s;
            animation-fill-mode: both;
            &.link {
                font-size: 24px;
                color: $white;
                line-height: 1;
                margin-right: 50px;
                animation-delay: 1s;
                animation-name: fadeOutLeft;
                &:last-child {
                    margin-right: 0;
                }
            }
            &.btn {
                animation-delay: 1s;
                animation-name: fadeOutRight;
            }
        }
    }
    // Responsive
    @media #{$desktop-device} {
        & .title {
            font-size: 50px;
        }
        & .desc {
            font-size: 20px;
            max-width: 800px;
        }
        & .links {
            & a {
                &.link {
                    font-size: 20px;
                }
            }
        }
    }
    @media #{$tablet-device} {
        & .title {
            font-size: 44px;
        }
        & .desc {
            font-size: 18px;
            max-width: 700px;
        }
        & .links {
            & a {
                &.link {
                    font-size: 18px;
                }
            }
        }
    }
    @media #{$large-mobile} {
        & .title {
            font-size: 30px;
        }
        & .desc {
            font-size: 16px;
        }
        & .links {
            & a {
                &.link {
                    font-size: 16px;
                    margin-right: 25px;
                }
                &.btn {
                    padding: 7px 30px;
                }
            }
        }
    }
    @media #{$small-mobile} {
        & .title {
            font-size: 24px;
        }
        & .desc {
            font-size: 14px;
        }
        & .links {
            & a {
                &.link {
                    font-size: 14px;
                }
                &.btn {
                    padding: 5px 25px;
                }
            }
        }
    }
    @media #{$extra-small-mobile} {
        & .title {
            font-size: 24px;
        }
        & .desc {
            margin-bottom: 25px;
        }
        & .links {
            flex-direction: column;
            & a {
                &.link {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.slick-current {
    & .hero-content {
        & .title {
            animation-name: fadeInUp;   
        }
        & .desc {
            animation-name: fadeInUp;
        }
        & .links {
            & a {
                &.link {
                    animation-name: fadeInLeft;
                }
                &.btn {
                    animation-name: fadeInRight;
                }
            }
        }
    }
}

/*Hero Image 2 (For Home 2)*/
.hero-image-2 {
    height: 850px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    // Responsive
    @media #{$laptop-device, $desktop-device} {
        height: 670px;
    }
    @media #{$tablet-device} {
        height: 400px;
    }
    @media #{$large-mobile} {
        height: 250px;
    }
}

/*Hero Content 2 (For Home 2)*/
.hero-content-2 {
    // padding-top: 50px;
    // padding-bottom: 60px;
    // max-width: 500px;
    // margin-left: 115px;
    & .title, & .desc {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
    & .title {
        color: $white;
        font-size: 50px;
        margin-bottom: 15px;
        animation-delay: 0.3s;
    }
    & .desc {
        color: $white;
        font-size: 18px;
        max-width: 550px;
        margin-bottom: 38px;
        animation-delay: 0.7s;
    }
    & .links {
        display: flex;
        flex-wrap: wrap;
        & a {
            animation-duration: 1s;
            animation-fill-mode: both;
            &.btn {
                animation-delay: 1s;
                animation-name: fadeOutUp;
            }
        }
    }
    // Responsive
    @media #{$laptop-device} {
        margin-left: 50px;
    }
    @media #{$desktop-device} {
        margin-left: 30px;
        margin-right: 30px;
        & .title {
            font-size: 44px;
        }
    }
    @media #{$tablet-device} {
        margin-left: auto;
        margin-right: auto;
    }
    @media #{$large-mobile} {
        max-width: 100%;
        padding: 50px;
        margin: 0;
        & .title {
            font-size: 30px;
        }
        & .desc {
            font-size: 16px;
        }
        & .links {
            & a {
                &.btn {
                    padding: 7px 30px;
                }
            }
        }
    }
    @media #{$small-mobile} {
        padding: 50px 30px;
        & .title {
            font-size: 24px;
        }
        & .desc {
            font-size: 14px;
        }
        & .links {
            & a {
                &.btn {
                    padding: 5px 25px;
                }
            }
        }
    }
    @media #{$extra-small-mobile} {
        & .title {
            font-size: 24px;
        }
        & .desc {
            margin-bottom: 25px;
        }
    }
}
.slick-current {
    & .hero-content-2 {
        & .title {
            animation-name: fadeInUp;   
        }
        & .desc {
            animation-name: fadeInUp;
        }
        & .links {
            & a {
                &.btn {
                    animation-name: fadeInUp;
                }
            }
        }
    }
}