/*** STATAMIC SCSS ***/

.bg-black {
    background-color: $black;
}

/**********************************/
/***    TC IMAGE CAPTION **********/
/**********************************/


.tc-image-caption1 {position: relative;overflow: hidden;}
.tc-image-caption1 img {transform: scale(1) translateY(0);transition: all .4s ease-in-out;}
.tc-image-caption1:hover img {transform: scale(1.2) translateY(-30px);}
.tc-image-caption1 .caption {position: absolute;bottom: 20px;left: 20px;z-index: 10;}
.tc-image-caption1:after {position: absolute;content: '';height: 100%;width: 100%;background-color: rgba(0, 0, 0, 0.6);-webkit-transition: opacity .25s ease-in-out;transition: opacity .3s ease-in-out;opacity: 0;top: 0; left: 0;}
.tc-image-caption1:hover:after {opacity: 1;}
.tc-image-caption1 .caption h3 {color: #fff;font-size: 19px;margin-bottom: 5px;transform: translateY(20px);transition: all .5s ease-in-out;opacity: 0;}
.tc-image-caption1 .caption p {transform: translateY(50px);color: rgba(255,255,255,0.7);opacity: 0;transition: all .5s ease-in-out;}
.tc-image-caption1:hover .caption h3, 
.tc-image-caption1:hover .caption p {opacity: 1;transform: translateY(0px);}
.tc-image-caption1 .link-wrap {position: absolute;top: 15px;right: 15px;z-index: 10;}
.tc-image-caption1 .link-wrap a {display: inline-block;height: 40px;width: 40px;line-height: 40px;background-color: rgba(255,255,255,0.1);text-align: center;color: #fff;font-size: 14px;opacity: 0;border-radius: 50%;-webkit-transition: all .5s ease-in-out;transition: all .5s ease-in-out;}
.tc-image-caption1 .link-wrap a:first-child {transform: translateY(-30px);margin-right: 2px;}
.tc-image-caption1 .link-wrap a:last-child {transform: translateY(-60px);}
.tc-image-caption1:hover .link-wrap a {opacity: 1;transform: translateY(0px);}
.tc-image-caption1 .link-wrap a:hover {background-color: rgba(255,255,255,0.4);}


/*** IFRAME OPTIONS ***/
// section iframe {
// border: 4px solid #b9b9b9;
// border-radius: 10px;
// padding: 2em;
// background-color: rgb(211, 211, 211);
// }


/*** HEADER ITEMS ***/
  
.header-items {
    display: grid;
    
    min-height: 90vh;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    color: #fff;
    grid-template-areas: "header-right"
                        "header-left";
    @include respond-above(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "header-left header-right";
        height: 90vh;
    }
}
.header-left {
    // justify-self: center;
    // align-self: center;
    position: relative;
    display: flex;
    .left-banner {
        position: absolute;
        height: 100%;
        width: 100%;
        background: 
        linear-gradient(
            rgba(23, 47, 88,.9), 
            rgba(23, 47, 88,.9)),
        url(/assets/media/backgrounds/calculator_bw.jpg);
        background-size: cover;
    }
    .hero-content-2 {
        align-self: center;
        width: 70%;
        margin-left: 5em;
    }
}
.single-service {
    li {margin-bottom: 1.5em;}
    ul.no-margin li {
        margin-bottom: 0;
    }
}

.header-right {
    justify-self: center;
    align-self: center;
    height: 100%;
    grid-area: header-right;
    .hero-content-right {

    }
}
.hero-image-2 {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    align-content: center;
    img {
        width: 75%;
        margin: 0 auto;
    }
}
.shadow {
    text-shadow: 3px 2px 2px rgba(0,0,0,.6);
}


/*** button override ***/


.btn {
    margin-bottom: 1em;
    @include respond-above(md) {
        margin-bottom: 0;
    }
}
