/*----------------------------------------*/
/*  08. Brand CSS
/*----------------------------------------*/

.brand-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    & img {
        opacity: 0.75;
        &:hover {
            opacity: 1;
        }
    }
}