/*----------------------------------------*/
/*  12. Team CSS
/*----------------------------------------*/

/*Team Wrapper*/
.team-wrap {
    margin-bottom: -30px;
}

/*Team*/
.team {
    margin-bottom: 30px;
    & .inner {
        & .image-holder {
            position: relative;
            & .image {
                position: relative;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background-color: $dark;
                    opacity: 0;
                    transition: all 0.3s ease 0s;
                }
                & img {
                    width: 100%;
                }
            }
            & .social {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                flex-direction: column;
                line-height: 1;
                opacity: 0;
                transition: all 0.3s ease 0s;
                & a {
                    margin: 5px;
                    text-align: center;
                    color: $white;
                    & i {
                        line-height: 20px;
                        width: 20px;
                    }
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        & .content {
            margin-top: 20px;
            & .name {
                line-height: 1;
            } 
            & .title {
                display: block;
                font-size: 14px;
                line-height: 1;
            } 
        }
        &:hover {
            & .image-holder {
                & .image {
                    &::before {
                        opacity: 0.5;
                    }
                }
                & .social {
                    opacity: 1;
                    left: 10px;
                }
            }
        }
    }
    @media #{$large-mobile} {
        & .inner {
            & .content {
                & .name {
                    font-size: 20px;
                }
            }
        }
    }
}

/*Team*/
.team-2 {
    margin-bottom: 30px;
    & .inner {
        & .image-holder {
            position: relative;
            & .image {
                position: relative;
                width: 220px;
                height: 220px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: auto;
                margin-right: auto;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background-color: $dark;
                    opacity: 0;
                    transition: all 0.3s ease 0s;
                }
                & img {
                    border-radius: 50%;
                    width: 100%;
                }
            }
        }
        & .content {
            margin-top: 20px;
            text-align: center;
            & .name {
                line-height: 1;
            } 
            & .title {
                display: block;
                font-size: 14px;
                line-height: 1;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            } 
            & .social {
                display: flex;
                justify-content: center;
                line-height: 1;
                & a {
                    margin: 5px;
                    text-align: center;
                    & i {
                        line-height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }
}