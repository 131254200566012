/*----------------------------------------*/
/*  15. Widget CSS
/*----------------------------------------*/


/* footer background */

.footerbak {
    background-size: cover;
    margin-top: -7px;
}
/*Footer Widget Wrapper*/
.footer-widget-wrap {
    margin-bottom: -40px;
}

/*Footer Widget*/
.footer-widget {
    color: rgba($white, 0.75);
    margin-bottom: 40px;
    padding: 0 2em;
    & .inner {}
}

/*Footer Copyright*/
.copyright {
    color: rgba($white, 0.75);
    font-size: 14px;
}